import Vue from 'vue'

import {
  message,
  Row,
  Col, 
  Anchor,
  Carousel,
  FormModel,
  Button,
  Input,
  Icon,
  Tabs,
  Modal
} from 'ant-design-vue'

Vue.use(Row)
Vue.use(Col)
Vue.use(Anchor)
Vue.use(Carousel)
Vue.use(FormModel)
Vue.use(Button)
Vue.use(Input)
Vue.use(Icon)
Vue.use(Tabs)
Vue.use(Modal)

Vue.prototype.$message = message