import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    show_nav_bg: true
  },
  mutations: {
    SET_NAV_BG(state, payload) {
      state.show_nav_bg = payload
    }
  },
  actions: {},
  modules: {},
});
