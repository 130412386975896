<template>
  <div id="app">
    <div id="nav" :class="{'nav-bg': $store.state.show_nav_bg}">
      <div class="container">
        <div class="l-wrap">
          <img src="@/assets/images/logo.png" alt="">
        </div>
        <div class="m-wrap">
          <router-link exact active-class="active" to="/">产品</router-link>
          <router-link active-class="active" to="/solution">解决方案</router-link>
          <router-link active-class="active" to="/case">案例</router-link>
          <router-link active-class="active" to="/partner">合作伙伴</router-link>
          <router-link active-class="active" to="/about">关于我们</router-link>
        </div>
        <div class="r-wrap" @click="rigister">
          免费试用
        </div>
      </div>
    </div>
    <router-view />

    <a-modal v-model="visible" title="请留下您的联系方式" :footer="null" :width="750">
      <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="姓名" prop="name">
          <a-input v-model="form.name" placeholder="请输入姓名" />
        </a-form-model-item>
        <a-form-model-item label="手机" prop="phone">
          <a-input v-model="form.phone" placeholder="请输入手机" />
        </a-form-model-item>
        <a-form-model-item label="企业名称" prop="company_name">
          <a-input v-model="form.company_name" placeholder="请输入企业名称" />
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 16, offset: 4 }">
          <a-button type="primary" @click="submit">
            提交
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  data() {
    return {
      visible: false,

      labelCol: { span: 4 },
      wrapperCol: { span: 16 },
      form: {
        name: '',
        phone: '',
        company_name: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入正确的手机号', trigger: 'blur', pattern: /^1[3|4|5|7|8][0-9]\d{8}$/ }
        ],
        company_name: [
          { required: true, message: '请输入企业名称', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    $route(to) {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    }
  },
  mounted() {
    // this.$router.push({
    //   name: 'Product'
    // })

    this.reset()
  },
  methods: {
    reset() {

    },
    rigister() {
      this.visible = true
    },
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          var url = ''
          var protocolStr = document.location.protocol
          if (protocolStr == "http:") {
            console.log("protocol = " + protocolStr)
            url = 'http://party.shuduier.com'
          } 
          if (protocolStr == "https:") {
            console.log("protocol = " + protocolStr)
            url = 'https://party.shuduier.com:8443'
          }

          axios({
            url: `${url}/qzzy`,
            method: 'post',
            data: {
              name: this.form.name,
              mobile: this.form.phone,
              crop: this.form.company_name
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 0) {
              this.$message.success('提交成功')
              this.visible = false
            } else {
              this.$message.error('提交失败')
            }
          }).catch(err => {
            console.log(err)
            this.$message.info(err.message)
          })
        } else {
          console.log('error')
          return false
        }
      })
    }
  }
}
</script>

<style lang="less">
.active{
  color: #0066FF !important;
  font-weight: 500;
}
#app{
  #nav{
    width: 100%;
    height: 110px;
    position: fixed;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: #fff;
    .container{
      width: 1200px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .l-wrap{
        img{
          display: block;
        }
      }
      .m-wrap{
        margin-left: -100px;
        a{
          font-size: 1.8rem;
          color: #303133;
          text-decoration: none;
          padding: 0 2rem;
        }
        a:hover{
          color: #0066FF;
        }
      }
      .r-wrap{
        width: 120px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        background-image: linear-gradient(to right, #3538FB, #92F0FE);
        border-radius: 8px;
        font-size: 1.6rem;
        color: #fff;
        cursor: pointer;
      }
      .r-wrap:hover{
        background-image: linear-gradient(to right, #2225DA, #92D3FE);
      }
    }
  }
}
.nav-bg{
  background-color: #fff;
}
</style>
