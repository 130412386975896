import Vue from "vue";
import VueRouter from "vue-router";
import Product from "../views/Product.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Product",
    component: Product,
  },
  {
    path: "/solution",
    name: "Solution",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Solution" */ "../views/Solution.vue"),
  },
  {
    path: "/case",
    name: "Case",
    component: () => import(/* webpackChunkName: "Case" */ "../views/Case.vue"),
  },
  {
    path: "/partner",
    name: "Solution",
    component: () => import(/* webpackChunkName: "Partner" */ "../views/Partner.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import(/* webpackChunkName: "About" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
