<template>
<div>
  <div class="header-wrap">
    <img class="l-decoration" src="@/assets/images/banner_triangle.png" alt="">
    <div class="r-decoration"></div>
    <img class="banner" src="@/assets/images/banner_bg.png" alt="">
    <div class="title-wrap">
      <div class="title">资产全生命周期管理</div>
      <div class="feature-wrap">
        <span class="feature" v-for="(item, index) in feature_list" :key="index">{{ item }}</span>
      </div>
      <!-- <div class="action">
        <div class="inner">免费试用</div>
      </div> -->
    </div>
  </div>
  <!-- <div class="nav-wrap">
    <div class="nav-container">
      <div class="nav-item-wrap" v-for="(item, index) in nav_list" :key="index">
        <span class="text">{{ item }}</span>
        <span class="flag" v-if="nav_index == index"></span>
      </div>
    </div>
  </div> -->
  <a-anchor
    style="width: 100%;" 
    :affix="true" 
    :offsetTop="110" 
    :targetOffset="170" 
    wrapperClass="anchor-nav-wrap"
    @change="anchor_changed"
  >
    <a-anchor-link v-for="item in nav_list" :key="item.id" :href="item.href" :title="item.name">
      <div class="flag" v-if="nav_index == item.id"></div>
    </a-anchor-link>
  </a-anchor>
  <div class="pain-point-wrap" id="resolve-pain-point">
    <div class="pain-point-container">
      <div class="l-wrap">
        <a-carousel autoplay :after-change="carousel_changed">
          <div class="index_0">
            <img style="width: 65rem;" src="@/assets/images/pain_point_1.png" alt="">
          </div>
          <div class="index_1">
            <img style="width: 65rem;" src="@/assets/images/pain_point_2.png" alt="">
          </div>
          <div class="index_2">
            <img style="width: 65rem;" src="@/assets/images/pain_point_3.png" alt="">
          </div>
        </a-carousel>
      </div>
      <div class="r-wrap">
        <div v-for="(item, index) in filter_pain_point_item" :key="index">
          <div class="title">{{ item.title }}</div>
          <div class="content">
            <p v-for="(sub_item, sub_index) in item.desc" :key="sub_index">{{ sub_item }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="ad">
    <img src="@/assets/images/AD.png" alt="">
  </div>
  <div class="advantage-wrap" id="advantages-and-opportunities">
    <div class="advantage">
      <div class="title">我们的优势</div>
      <div class="container">
        <div class="item-wrap">
          <img class="icon" src="@/assets/images/icon_01.png" alt="">
          <div class="feature">提高资产管理效率</div>
          <div class="sub-feature">资产效果平均提高</div>
          <div class="percent">50%</div>
        </div>
        <div class="item-wrap">
          <img class="icon" src="@/assets/images/icon_02.png" alt="">
          <div class="feature">降低维修成功</div>
          <div class="sub-feature">有效降低维修维护成本</div>
          <div class="percent" style="color: #17E0C7;">30%</div>
        </div>
        <div class="item-wrap">
          <img class="icon" src="@/assets/images/icon_03.png" alt="">
          <div class="feature">提高资产利用</div>
          <div class="sub-feature">资产利用率平均可提高</div>
          <div class="percent">20%</div>
        </div>
      </div>
    </div>
    <div class="advantage">
      <div class="title">为什么选择AI资管</div>
      <div class="container">
        <div class="item-wrap">
          <img class="icon" src="@/assets/images/icon_04.png" alt="">
          <div class="feature">优化资产配置</div>
          <div class="sub-feature">提升资产价值、优化人、物、财力等资源的更完善配置</div>
        </div>
        <div class="item-wrap">
          <img class="icon" src="@/assets/images/icon_05.png" alt="">
          <div class="feature">提高企业竞争力</div>
          <div class="sub-feature">有效改善用户满意度，提高 企业数据安全、用户隐私保护、文化资产保存等等。</div>
        </div>
        <div class="item-wrap">
          <img class="icon" src="@/assets/images/icon_06.png" alt="">
          <div class="feature">数字化转型</div>
          <div class="sub-feature">IOT、AI、AR等新技术的应用，帮助企业数字化转型</div>
        </div>
      </div>
    </div>
  </div>
  <div class="product" id="main-product">
    <div class="container">
      <div class="title-wrap">
        <div class="l">主要产品</div>
        <div class="r">查看更多</div>
      </div>
      <div class="content-wrap">
        <div class="item-wrap">
          <img src="@/assets/images/product_1.png" alt="">
          <div class="title">AI资管APP/小程序</div>
          <div class="sub-title">盘点/巡检/维修/审批/业务工单</div>
        </div>
        <div class="item-wrap">
          <img src="@/assets/images/product_2.png" alt="">
          <div class="title">AI资管后台管理系统</div>
          <div class="sub-title">AI智能化分析资产服务报告</div>
        </div>
        <div class="item-wrap">
          <img src="@/assets/images/product_3.png" alt="">
          <div class="title">AI资管PDA应用</div>
          <div class="sub-title">批量盘点/点巡检/工单/有线/无线/数据</div>
        </div>
      </div>
    </div>
  </div>
  <div class="technical-highlight" id="technical-highlight">
    <div class="title">技术亮点</div>
    <div class="container">
      <div class="l-layout">
        <div class="l-item">
          <img src="@/assets/images/technical_highlight_icon_1.png" alt="">
          <div class="name">RFID 技术</div>
          <div class="desc">RFID 技术成熟，资产一物一码一卡</div>
        </div>
        <div class="l-item">
          <img src="@/assets/images/technical_highlight_icon_2.png" alt="">
          <div class="name">AI 智能化</div>
          <div class="desc">用 AI 对资产分析、预警、建知识库</div>
        </div>
        <div class="l-item">
          <img src="@/assets/images/technical_highlight_icon_3.png" alt="">
          <div class="name">AR 可视化</div>
          <div class="desc">点巡视、维修维护过程可视化</div>
        </div>
      </div>
      <div class="center-layout">
        <img class="img" src="@/assets/images/technical_highlight_bg.png" alt="">
        <div class="name-wrap">
          <div>EAM资产</div>
          <div>全生命周期管理</div>
        </div>
      </div>
      <div class="r-layout">
        <div class="r-item">
          <img src="@/assets/images/technical_highlight_icon_4.png" alt="">
          <div class="name">IoT 物联网</div>
          <div class="desc">资产数据区块链，加强 IoT 管理应用</div>
        </div>
        <div class="r-item">
          <img src="@/assets/images/technical_highlight_icon_5.png" alt="">
          <div class="name">云原生</div>
          <div class="desc">API 对接 ERP/OA/HR 等，低</div>
        </div>
        <div class="r-item">
          <img src="@/assets/images/technical_highlight_icon_6.png" alt="">
          <div class="name">数字化转型</div>
          <div class="desc">移动端/云计算，高效提高高资产使用价值</div>
        </div>
      </div>
    </div>
  </div>
  <div class="functional" id="functional-characteristics">
    <img class="l-circle-bg" src="@/assets/images/functional_bg_left.png" alt="">
    <div class="container">
      <div class="title">功能特点</div>
      <div class="content">
        <div class="top-layout">
          <div class="block-wrap">
            <div class="l-name">数据标准</div>
            <div class="r-desc">
              <div>· 数据要素标准化</div>
              <div>· 资产业务合规化</div>
            </div>
          </div>
          <div class="dynamic-line">
            <div class="content-wrap">
              <img src="@/assets/images/arrow_right.png" alt="">
              <div class="spec">RFID 资产盘点</div>
            </div>
          </div>
          <div class="block-wrap">
            <div class="l-name">协作共享</div>
            <div class="r-desc">
              <div>· 审批流程规范化</div>
              <div>· 协同工作共享化</div>
            </div>
          </div>
          <div class="dynamic-line">
            <div class="content-wrap">
              <img src="@/assets/images/arrow_right.png" alt="">
              <div class="spec">业务审批规范</div>
            </div>
          </div>
          <div class="block-wrap">
            <div class="l-name">数据采集</div>
            <div class="r-desc">
              <div>· 数据采集多样化</div>
              <div>· 资产应用统一化</div>
            </div>
          </div>
        </div>
        <div class="center-layout">
          <div class="dynamic-line-left">
            <div class="content-wrap">
              <img src="@/assets/images/arrow_top.png" alt="">
              <div class="spec">
                <div>EAM 资产</div>
                <div>全生命周期</div>
              </div>
            </div>
          </div>
          <img src="@/assets/images/functional_digitization.png" alt="">
          <div class="dynamic-line-right">
            <div class="content-wrap">
              <img src="@/assets/images/arrow_bottom.png" alt="">
              <div class="spec">
                <div>AR 点巡检</div>
                <div>可视化</div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-layout">
          <div class="block-wrap">
            <div class="l-name">应用服务</div>
            <div class="r-desc">
              <div>· 移动应用智能化</div>
              <div>· 服务响应快速化</div>
            </div>
          </div>
          <div class="dynamic-line">
            <div class="content-wrap">
              <img src="@/assets/images/arrow_left.png" alt="">
              <div class="spec">资产利用增效</div>
            </div>
          </div>
          <div class="block-wrap">
            <div class="l-name">数据完整</div>
            <div class="r-desc">
              <div>· 过程处理可视化</div>
              <div>· 资产分析准确化</div>
            </div>
          </div>
          <div class="dynamic-line">
            <div class="content-wrap">
              <img src="@/assets/images/arrow_left.png" alt="">
              <div class="spec">AI 智能分析</div>
            </div>
          </div>
          <div class="block-wrap">
            <div class="l-name">场景融合</div>
            <div class="r-desc">
              <div>· 资产对接敏感化</div>
              <div>· 资产分析准确化</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img class="r-circle-bg" src="@/assets/images/functional_bg_right.png" alt="">
  </div>
  <div class="company-logo">
    <div class="title">10,000+家企业的共同选择</div>
    <a-carousel class="carousel-wrap" autoplay>
      <div class="carousel-content">
        <a-row>
          <a-col class="col" :span="6">
            <img src="@/assets/images/logos/1.png" alt="">
          </a-col>
          <a-col class="col" :span="6">
            <img src="@/assets/images/logos/2.png" alt="">
          </a-col>
          <a-col class="col" :span="6">
            <img src="@/assets/images/logos/3.png" alt="">
          </a-col>
          <a-col class="col" :span="6">
            <img src="@/assets/images/logos/4.png" alt="">
          </a-col>
        </a-row>
        <a-row>
          <a-col class="col" :span="6">
            <img src="@/assets/images/logos/5.png" alt="">
          </a-col>
          <a-col class="col" :span="6">
            <img src="@/assets/images/logos/6.png" alt="">
          </a-col>
          <a-col class="col" :span="6">
            <img src="@/assets/images/logos/7.png" alt="">
          </a-col>
          <a-col class="col" :span="6">
            <img src="@/assets/images/logos/8.png" alt="">
          </a-col>
        </a-row>
      </div>
    </a-carousel>
  </div>
  <div class="experience">
    <img class="l-desc" src="@/assets/images/l_rect.png" alt="">
    <img class="r-desc" src="@/assets/images/r_rect.png" alt="">
    <div class="opration">
      <div class="title">即刻开启体验</div>
      <div class="sub-title">现在开始尝试更简单高效的工作方式</div>
      <!-- <div class="action">免费试用</div> -->
    </div>
    <div class="kefu">
      <img class="img" src="@/assets/images/kefu.png" alt="">
    </div>
  </div>
  <Footer />
</div>
</template>

<script>

import Footer from '@/components/Footer'

export default {
  name: 'Product',
  components: {
    Footer
  },
  data() {
    return {
      feature_list: ['盘点更高效', '巡检更便捷', '产品更好用'],
      // nav_list: ['解决痛点', '优势与机遇', '主要产品', '技术亮点', '功能特色'],
      nav_list: [
        {
          id: 0,
          name: '解决痛点',
          href: '#resolve-pain-point'
        },
        {
          id: 1,
          name: '优势机遇',
          href: '#advantages-and-opportunities'
        },
        {
          id: 2,
          name: '主要产品',
          href: '#main-product'
        },
        {
          id: 3,
          name: '技术亮点',
          href: '#technical-highlight'
        },
        {
          id: 4,
          name: '功能特色',
          href: '#functional-characteristics'
        }
      ],
      nav_index: 0,

      pain_point_list: [
        {
          title: '「 人与物规范化 」解决资产管理业务痛点',
          desc: [
            '资产数据来源不统一，数据较分散，管理混乱资产位置状态跟踪难，跨地域，跨系统管理难账务，卡册，实物数据不符',
            '盘点效率低，资产设备点巡检不规范，维修保养数据不齐资产财务规划与实体监管和实报数据偏差大，闲置资产统计不清，报废资产不能及时清理，资产归属责权不清',
            '厂商供应商服务不规范、数据资产规划、管理、利用、保护不合规；资产设备的折旧计提不统一，清算难度大、资产盘亏损失难以掌控；资产清查效率低、资产设备的预警监测少、点检维修滞后、资产运营管理难、资产流动周转性率低、资产利用率低、损耗率高、投入产出低、资产评估不准确，资产保险理赔不清...',
          ]
        },
        {
          title: '「 人与人标准化 」解决业务管理难点',
          desc: [
            '资产分类归集难度大，资产审批管理流程不健全；资产数据规范化、标准化、数字化的应用程度低；部门/个人之间的资产协同应用管理效率低；资产信息无法及时准确地掌握，数据质量不高；资产运营管理责权利不清晰，浪费成本高；资产流动性管理和资产的盘活利用率低； ',
            '资产盘点、点巡检、维修保养过程中的数据留痕；',
            '资产生命周期管理的监控与审计标准的流程；',
            '资产财务规划目标和各部门资产使用报告标准化；',
            '资产绩效评价、风险评估和分析标准的应用流程。',
          ]
        },
        {
          title: '「 物与物智能化 」技术方案选型重点',
          desc: [
            '完善资产管理标准化、规范化、数字化体系。采用先进的技术构建“资产全生命周期管理”系统灵活部署应用，多级互联，实现万物互联。',
            '开放式架构，API接口共享，可轻量级敏捷交付；移动端多态应用、数据交互访问，可视化程度高。',
            '界面友好、审批流程定制简便、资产报告维度广；基于RFID技术的资产ID画像，涵盖过程管理数据；及时采集资产数据，可对接IoT、ERP、OA等系统。'
          ]
        }
      ],
      pain_point_item_index: 0
    }
  },
  computed: {
    filter_pain_point_item() {
      return this.pain_point_list.filter((item, index) => index == this.pain_point_item_index)
    }
  },
  mounted() {
    // 监听页面滚动事件
    // window.addEventListener('scroll', this.scroll_handler)
  },
  methods: {
    scroll_handler() {
      let scroll_top =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop

      // console.log(scroll_top)

      if (scroll_top >= 675) {
        this.$store.commit('SET_NAV_BG', true)
      } else {
        this.$store.commit('SET_NAV_BG', false)
      }
    },
    anchor_changed(current_active_link) {
      for (const item of this.nav_list) {
        if (item.href == current_active_link) {
          this.nav_index = item.id
        }
      }
    },
    carousel_changed(current) {
      this.pain_point_item_index = current
    }
  }
}
</script>

<style>
@media screen and (max-width: 1500px) {
    .title-wrap{
      left: 10% !important;
    }
    .banner{
      right: 7.5% !important;
    }
}
</style>
<style lang="less" scoped>
.header-wrap{
  position: relative;
  height: 800px;
  background-image: linear-gradient(to bottom, #FBFCFF, #EEF5FF);
  .l-decoration{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 88.2rem;
    height: 69.2rem;
  }
  .banner{
    position: absolute;
    right: 15%;
    bottom: 0;
  }
  .r-decoration{
    position: absolute;
    top: 23rem;
    right: 10%;
    width: 31.8rem;
    height: 31.8rem;
    background: linear-gradient(224deg, #FFF4BE 0%, #B5FAFF 100%);
    opacity: 0.69;
    filter: blur(50px);
  }
  .title-wrap{
    position: absolute;
    top: 32.5rem;
    left: 20%;
    .title{
      font-size: 4.6rem;
      font-weight: bold;
    }
    .feature-wrap{
      margin: 1.5rem -1.5rem;
      .feature{
        color: rgba(43,47,55,.7);
        font-size: 2.4rem;
        margin: 0 1.5rem;
      }
    }
    .action{
      width: 18.6rem;
      height: 4.8rem;
      line-height: 4.8rem;
      text-align: center;
      font-size: 1.8rem;
      margin: 3rem 0;
      border-radius: 8px;
      background: linear-gradient(to right, #3D48FB, #83D3FE);
      padding: 2px;
      .inner{
        width: 100%;
        height: 100%;
        border-radius: 6px;
        background-color: #fff;
      }
    }
  }
}
.nav-wrap{
  display: flex;
  justify-content: center;
  background-color: #4A3F3C;
  .nav-container{
    display: flex;
    justify-content: center;
    width: 1400px;
    height: 6rem;
    line-height: 6rem;
    .nav-item-wrap{
      position: relative;
      display: flex;
      justify-content: center;
      padding: 0 4rem;
      cursor: pointer;
      .text{
        color: #fff;
        font-size: 1.6rem;
      }
      .flag{
        position: absolute;
        bottom: 0;
        display: inline-block;
        width: 2rem;
        height: 0.4rem;
        background-color: #fff;
      }
    }
  }
}
.is-fixed{
  width: 100%;
  height: 6rem;
  position: fixed;
  top: 110px;
  z-index: 1;
}
/deep/ .anchor-nav-wrap{
  height: 6rem;
  display: flex;
  justify-content: center;
  background-color: #4A3F3C;
}
/deep/ .anchor-nav-wrap .ant-anchor{
  width: 1200px;
  display: flex;
  justify-content: center;
  padding-left: 2px;
  padding-right: 2px;
}
/deep/ .anchor-nav-wrap .ant-anchor-ink{
  display: none;
}
/deep/ .anchor-nav-wrap .ant-anchor-link{
  padding: 0 4rem;
  line-height: 6rem;
  font-size: 1.6rem;
  position: relative;
  display: flex;
  justify-content: center;
}
/deep/ .anchor-nav-wrap .ant-anchor-link .flag{
  position: absolute;
  bottom: 0;
  width: 20px;
  height: 4px;
  background-color: #fff;
}
/deep/ .anchor-nav-wrap .ant-anchor-link-title{
  color: #fff;
}
.pain-point-wrap{
  height: 523px;
  background-color: #4F51DC;
  display: flex;
  justify-content: center;
  .pain-point-container{
    width: 1200px;
    display: flex;
    align-items: center;
    .l-wrap{
      width: 65rem;
      height: 40rem;
      flex-shrink: 0;
      img{
        display: block;
      }
    }
    .r-wrap{
      padding-left: 7.5rem;
      .title{
        font-size: 2.4rem;
        color: #fff;
        margin-bottom: 4rem;
      }
      .content{
        font-size: 1.4rem;
        color: #fff;
        line-height: 2.2rem;
      }
    }
  }
}
/deep/ .ant-carousel .slick-dots-bottom{
  bottom: -7px;
}
.ad{
  display: flex;
  justify-content: center;
  img{
    display: block;
    width: 1200px;
  }
}
.advantage-wrap{
  padding-top: 2px;
  padding-bottom: 8rem;
  background-image: linear-gradient(to bottom, #fff, #F0F8FE);
}
.advantage{
  .title{
    font-size: 3.6rem;
    font-weight: bold;
    margin-top: 6rem;
    margin-bottom: 8rem;
    text-align: center;
  }
  .container{
    display: flex;
    justify-content: center;
    .item-wrap{
      width: 38rem;
      height: 21rem;
      background-color: #fff;
      border-radius: 12px;
      margin: 0 0.6rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-shadow: 0 15px 30px rgba(7,106,255,0.075);
      padding: 0 88px;
      box-sizing: border-box;
      .icon{
        display: block;
        margin-top: -50px;
      }
      .feature{
        font-size: 2rem;
        font-weight: bold;
        margin-top: -15px;
      }
      .sub-feature{
        font-size: 1.6rem;
        color: #80879A;
        margin: 15px 0 5px;
        line-height: 2.4rem;
      }
      .percent{
        font-size: 3.2rem;
        font-weight: bold;
        color: #FF5555;
      }
    }
  }
}
.product{
  display: flex;
  justify-content: center;
  .container{
    width: 1200px;
    .title-wrap{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 40px 0 10px;
      border-bottom: 1px solid rgba(0,0,0,.1);
      .l{
        font-size: 2rem;
      }
      .r{
        font-size: 1.4rem;
        color: #80879A;
      }
    }
    .content-wrap{
      display: flex;
      justify-content: center;
      .item-wrap{
        text-align: center;
        padding: 30px;
        img{
          display: block;
          width: 30rem;
        }
        .title{
          font-size: 2.2rem;
          margin: 28px 0 7px;
          font-weight: 750;
        }
        .sub-title{
          font-size: 1.6rem;
          color: #80879A;
        }
      }
    }
  }
}
.technical-highlight{
  background-image: linear-gradient(to bottom, #fff, #F0F8FE);
  padding-top: 5rem;
  .title{
    font-size: 3.6rem;
    font-weight: bold;
    margin-bottom: 4rem;
    text-align: center;
  }
  .container{
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
    .l-layout{
      width: 300px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .l-item{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin: 24px 0;
        .name{
          font-size: 2.4rem;
        }
        .desc{
          color: #80879A;
          font-size: 1.6rem;
          margin: 8px 0 0;
        }
      }
    }
    .center-layout{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 40px;
      .img{
        width: 45rem;
        display: block;
      }
      .name-wrap{
        font-size: 2rem;
        font-weight: bold;
        text-align: center;
        margin-top: -50px;
      }
    }
    .r-layout{
      width: 300px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .r-item{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 24px 0;
        .name{
          font-size: 2.4rem;
        }
        .desc{
          color: #80879A;
          font-size: 1.6rem;
          margin: 8px 0 0;
        }
      }
    }
  }
}
.functional{
  position: relative;
  background-color: #4F51DC;
  padding: 60px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .l-circle-bg{
    position: absolute;
    left: 0;
  }
  .container{
    position: relative;
    z-index: 1;
    .title{
      font-size: 3.6rem;
      font-weight: bold;
      margin-bottom: 4.5rem;
      text-align: center;
      color: #fff;
    }
  }
  .r-circle-bg{
    position: absolute;
    right: 0;
  }
  .content{
    width: 100rem;
    .top-layout, .bottom-layout{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .block-wrap{
        width: 24.8rem;
        height: 15.5rem;
        background-image: linear-gradient(to bottom, #6568E1, #6568E1);
        padding: 2.4rem;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #fff;
        .l-name{
          font-size: 2.4rem;
        }
        .r-desc{
          font-size: 1.6rem;
          line-height: 2.4rem;
          text-align: right;
        }
      }
      .dynamic-line{
        width: 15rem;
        .content-wrap{
          width: 100%;
          position: relative;
          text-align: center;
          .spec{
            width: 100%;
            position: absolute;
            color: #13D9EF;
            font-size: 1.4rem;
            margin: 8px 0;
          }
        }
      }
    }
    .center-layout{
      display: flex;
      justify-content: space-between;
      margin: 75px 0;
      .dynamic-line-left{
        width: 24.8rem;
        .content-wrap{
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          .spec{
            position: absolute;
            right: 150px;
            color: #13D9EF;
            font-size: 1.4rem;
            text-align: right;
          }
        }
      }
      .dynamic-line-right{
        width: 24.8rem;
        .content-wrap{
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          .spec{
            position: absolute;
            left: 150px;
            color: #13D9EF;
            font-size: 1.4rem;
            text-align: left;
          }
        }
      }
    }
  }
}
.company-logo{
  padding: 6rem 0;
  .title{
    font-size: 3.6rem;
    font-weight: bold;
    margin-bottom: 4rem;
    text-align: center;
  }
  .carousel-wrap{
    width: 1200px;
    margin: 0 auto;
    .carousel-content{
      .col{
        display: flex;
        justify-content: center;
        margin: 12px 0;
      }
    }
  }
}
.experience{
  position: relative;
  height: 50rem;
  background-color: #F0F8FE;
  .l-desc{
    position: absolute;
    top: 0;
    left: 0;
  }
  .r-desc{
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .kefu{
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    .img{
      width: 1200px;
      display: block;
    }
  }
  .opration{
    display: flex;
    flex-direction: column;
    align-items: center;
    .title{
      font-size: 3.6rem;
      font-weight: bold;
      margin-top: 100px;
    }
    .sub-title{
      font-size: 2.4rem;
      margin: 20px 0 40px;
      color: #80879A;
    }
    .action{
      width: 120px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background-image: linear-gradient(to right, #3538FB, #92F0FE);
      border-radius: 8px;
      font-size: 1.6rem;
      color: #fff;
      cursor: pointer;
    }
    .action:hover{
      background-image: linear-gradient(to right, #2225DA, #92D3FE);
    }
  }
}
</style>
